import { Venue } from "../models/models";

type VenueAddressProps = {
  venue: Venue;
  condensed?: boolean;
};
export function VenueAddress({ condensed, venue }: VenueAddressProps) {
  return (
    <>
      {condensed ? (
        <p>
          {venue.address} {venue.city?.name} {venue.province} {venue.postalCode}
          <br />
          {venue.phone}
        </p>
      ) : (
        <p className={"whitespace-pre-line"}>
          {venue.address}
          <br />
          {venue.city && (
            <>
              {venue.city.name} {venue.province}
            </>
          )}
          {!venue.city && (
            <>
              {venue.province} {venue.country}
            </>
          )}
          <br />
          {venue.postalCode}
          <br />
          {venue.phone}
        </p>
      )}
    </>
  );
}
