import Image from "next/image";
import React from "react";
import { Product } from "../models/models";

export default function ProductThumbnail({
  product,
  thumbnail,
}: {
  product: Product;
  thumbnail?: string | null;
}) {
  return (
    <>
      {product.thumbnail && (
        <Image
          alt={product.title}
          src={thumbnail ? thumbnail : (product.thumbnail as string)}
          priority={true}
          width={500}
          height={500}
          className="w-full h-full object-center object-cover"
        />
      )}
    </>
  );
}
