"use client";
import { Brand, Page } from "@models/models";

export default function getMenuItemResolvedUrl(brand: Brand, page: Page) {
  if (page.is_external && page.url) return page.url;
  if (isRootDomain()) {
    return `/b/${brand.slug}/${page.slug}`;
  } else {
    return `/${page.slug}`;
  }
}

export function isRootDomain() {
  return location.host === (process.env.NEXT_PUBLIC_ROOT_DOMAIN as string);
}
