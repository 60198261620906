import { Product, ProductVariant } from "../models/models";
import dayjs from "dayjs";
import { formatDate } from "@jumpcomedy/utils";
import { Cart } from "@medusajs/medusa";

dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/isSameOrAfter"));
export const consolidatedShowtimes = (
  product: Product,
  showPast: boolean = false,
) => {
  let showtimes;
  if (product.inactiveVariants || product.activeVariants) {
    showtimes = (showPast ? product.inactiveVariants : product.activeVariants)
      .filter((v) => v.display_showtime)
      .map(
        (v) =>
          formatDate(v.startsAt) +
          (v.virtual_event_timezone ? ` ${v.virtual_event_timezone}` : ""),
      );
  } else {
    showtimes = product.variants
      .filter((v) => v.display_showtime)
      .map(
        (v) =>
          formatDate(v.startsAt) +
          (v.virtual_event_timezone ? ` ${v.virtual_event_timezone}` : ""),
      );
  }
  if (!showPast && showtimes.length > 2) {
    return showtimes.slice(0, 2).concat("More...");
  } else if (showPast && showtimes.length > 2) {
    return [`${showtimes.length} shows`];
  }
  return [...new Set(showtimes)];
};
export const firstVariant = (
  variants: ProductVariant[],
): ProductVariant | undefined => {
  return variants && variants.length !== 0 ? variants[0] : undefined;
};

export function firstVariantCanBeAddedToCart(product: Product) {
  if (product.activeVariants.length === 0) {
    return false;
  } else {
    const first = product.activeVariants[0];
    return (
      (first.manage_inventory && first.inventory_quantity !== 0) ||
      product.is_giftcard
    );
  }
}

export const isAvailableForSale = (product: Product, cart: Cart): boolean => {
  if (!product.availableAt) {
    return true;
  } else {
    // @ts-ignore
    const today = dayjs.tz(dayjs(), product.venueArrangement.venue.timezone);
    // @ts-ignore
    const availableAt = dayjs(product.availableAt).tz(
      product.venueArrangement.venue.timezone,
    );
    const date_check_ok = today.isSameOrAfter(availableAt);
    const pre_sale_check_ok = (
      cart.context.pre_sale_auths as { product_id: string }[]
    )?.find((auth) => auth.product_id === product.id);
    return date_check_ok || pre_sale_check_ok;
  }
};

export function formatAvailableDate(product: Product): Date {
  // @ts-ignore
  return (
    dayjs(product.availableAt)
      // @ts-ignore
      .tz(product.venueArrangement.venue.timezone, true)
      .format("MMM D, YYYY")
  );
}
