import RatingStars from "@components/rating-stars";
import { getVariantShowtimeOnly } from "@components/variant-info";
import { formatDate } from "@jumpcomedy/utils";
import * as React from "react";
import { Customer, Review } from "@models/models";
import { FaCircleCheck, FaCopy } from "react-icons/fa6";
import CopyToClipboard from "@components/copy-to-clipboard";

export default function CustomerReview({
  review,
  customer,
  showProductTitle = true,
  showShowtime = true,
  showInCard = false,
}: {
  review: Review;
  customer?: Customer;
  showProductTitle?: boolean;
  showShowtime?: boolean;
  showInCard?: boolean;
}) {
  return (
    <div className={`${showInCard ? "shadow-md p-4" : ""}`}>
      <div className={"flex flex-col md:flex-row items-center gap-2 mb-2"}>
        <div>
          <RatingStars rating={review.rating} />
        </div>
        <div className={"text-xs"}>
          {customer ? (
            <div className={"flex gap-x-2"}>
              <div>
                {customer.first_name} {customer.last_name}
              </div>
              <div>
                <CopyToClipboard text={customer.email} message={"Copied email"}>
                  <div className={"flex gap-x-2 items-center"}>
                    <div>{customer.email}</div>
                    <div>
                      <FaCopy />
                    </div>
                  </div>
                </CopyToClipboard>
              </div>
            </div>
          ) : (
            <div className={"flex gap-x-2 items-center"}>
              <FaCircleCheck className={"text-lg text-blue-600"} />
              <div>Verified Customer</div>
            </div>
          )}
        </div>
      </div>
      <div className={"text-sm text-gray-500 mb-2"}>
        {showProductTitle && <>{review.variant.product.title} - </>}
        {showShowtime && <>{getVariantShowtimeOnly(review.variant)}</>}
        <div className={"text-xs mt-1"}>
          Reviewed on {formatDate(review.created_at)}
        </div>
      </div>
      <div className={"whitespace-pre-wrap text-sm"}>{review.note}</div>
    </div>
  );
}
