import { useEffect, useState } from "react";
import Medusa from "@medusajs/medusa-js";
import { useAdminUser } from "./admin-user";

export function useMedusa() {
  const [medusa, setMedusa] = useState<Medusa>(
    new Medusa({
      baseUrl: process.env.NEXT_PUBLIC_MEDUSA_BASE_URL as string,
      maxRetries: 8,
    }),
  );
  const { loggedIn, apiToken } = useAdminUser({ redirectIfLoggedOut: false });

  useEffect(() => {
    if (loggedIn) {
      const medusa = new Medusa({
        baseUrl: process.env.NEXT_PUBLIC_MEDUSA_BASE_URL as string,
        maxRetries: 3,
        apiKey: apiToken,
      });
      setMedusa(medusa);
    }
  }, [loggedIn]);

  return { medusa };
}
