import { Cart, LineItem, Order } from "@medusajs/medusa";
import { Product } from "../models/models";

export function sendGaEvent(eventName: string, rest: {}) {
  // @ts-ignore
  window.dataLayer.push({
    event: eventName,
    ...rest,
  });
}

export function toGaAmount(amountInCents: number | null | undefined): number {
  return (amountInCents ? amountInCents : 0) / 100;
}

export function createPurchasePayload(order: Order) {
  return {
    currency: order.currency_code.toUpperCase(),
    transaction_id: order.id,
    value: toGaAmount(order.total),
    tax: toGaAmount(order.tax_total),
    items: createItemsPayload(order.items),
  };
}

export function createViewCartPayload(cart: Cart) {
  return {
    currency: cart.region.currency_code.toUpperCase(),
    value: toGaAmount(cart.total),
    items: createItemsPayload(cart.items),
  };
}

export function createAddToCartPayload(
  cart: Cart,
  variantId: string | undefined,
) {
  if (!variantId || !cart || !cart.items) return {};
  const lineItem = cart.items.find((i) => i.variant.id === variantId);
  if (!lineItem) return {};
  return {
    currency: cart.region.currency_code.toUpperCase(),
    value: toGaAmount(lineItem.total),
    items: createItemsPayload([lineItem]),
  };
}

export function createSelectItemPayload(
  product: Product,
  listId: string | undefined,
  listName: string | undefined,
) {
  return {
    item_list_id: listId ? listId : "item-list-id",
    item_list_name: listName ? listName : "item-list",
    items: {
      item_id: product.id,
      item_name: product.title,
    },
  };
}

export function createBeginCheckoutPayload(cart: Cart) {
  return createViewCartPayload(cart);
}

function createItemsPayload(items: LineItem[]) {
  return items.map((li) => ({
    item_id: li.variant.product_id,
    item_name: li.variant.product.title,
    quantity: li.quantity,
    price: toGaAmount(li.unit_price),
    item_variant: li.variant.id,
  }));
}
