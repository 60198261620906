export type StockIndicatorProps = {
  inventory_quantity?: number;
  original_inventory_quantity?: number;
  lowInventoryWarningThreshold?: number;
  low_inventory_message?: string;
  inStock?: boolean;
  in_stock_label?: string;
};

const DEFAULT_LOW_INVENTORY_WARNING_THRESHOLD = 10;

export default function getStockIndicatorMessage(params: StockIndicatorProps) {
  if (!params.lowInventoryWarningThreshold) {
    params.lowInventoryWarningThreshold =
      DEFAULT_LOW_INVENTORY_WARNING_THRESHOLD;
  }
  if (
    params.low_inventory_message &&
    params.inventory_quantity &&
    params.lowInventoryWarningThreshold
  ) {
    if (params.inventory_quantity <= params.lowInventoryWarningThreshold) {
      return formatMessage(params);
    }
  } else if (params.inventory_quantity && params.lowInventoryWarningThreshold) {
    return `Only ${params.inventory_quantity} left`;
  }
}

function formatMessage(props: StockIndicatorProps) {
  if (props.low_inventory_message && props.inventory_quantity) {
    let result = props.low_inventory_message.replace(
      /\{\{remaining_quantity\}\}/,
      `${props.inventory_quantity}`,
    );
    if (props.original_inventory_quantity) {
      result = result.replace(
        /\{\{remaining_percentage\}\}/,
        `${Math.round((props.inventory_quantity / props.original_inventory_quantity) * 100)}%`,
      );
      result = result.replace(
        /\{\{remaining_percentage_rounded_to_5\}\}/,
        `${Math.round(roundToNearestFive((props.inventory_quantity / props.original_inventory_quantity) * 100))}%`,
      );
      result = result.replace(
        /\{\{quantity_5_or_less_percentage_otherwise\}\}/,
        `${props.inventory_quantity <= 5 ? props.inventory_quantity : `${Math.round((props.inventory_quantity / props.original_inventory_quantity) * 100)}%`}`,
      );
    }
    return result;
  }
}

function roundToNearestFive(num: number): number {
  return Math.round(num / 5) * 5;
}
