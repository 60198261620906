import { Form, Formik } from "formik";
import { useCart } from "@hooks/cart";
import { useErrorHandler } from "@hooks/error-handler";
import { useState } from "react";
import { TextInput } from "@components/forms/text-input";
import { useMedusa } from "@hooks/medusa";
import { useAppDispatch } from "@store/store";
import { replaceCart } from "@store/cart-slice";

export default function PreSaleCodeForm({
  pre_sale_code,
  product_id,
}: {
  product_id: string;
  pre_sale_code: string;
}) {
  const [showForm, setShowForm] = useState(false);
  const cart = useCart();
  const { medusa } = useMedusa();
  const dispatch = useAppDispatch();
  const handlePreSaleCodeSubmission = useErrorHandler(
    async (values: { pre_sale_code: string }) => {
      if (!cart) return;
      if (
        values.pre_sale_code.trim().toLowerCase() ===
        pre_sale_code?.trim().toLowerCase()
      ) {
        const cart_response = await medusa.carts.update(cart.id as string, {
          context: {
            pre_sale_auths: [{ product_id, pre_sale_code }],
          },
        });
        dispatch(replaceCart(cart_response.cart));
      } else {
        throw new Error("Invalid pre-sale code");
      }
    },
  );
  return (
    <>
      {showForm && (
        <Formik
          initialValues={{
            pre_sale_code: "",
          }}
          onSubmit={handlePreSaleCodeSubmission}
        >
          <Form>
            <div className={"flex flex-col w-64 gap-4 bg-gray-100 rounded p-4"}>
              <TextInput name={"pre_sale_code"} label={"Enter Pre-Sale Code"} />
              <div className={"flex gap-x-2 justify-end"}>
                <button
                  type={"button"}
                  className={"btn btn-sm"}
                  onClick={() => setShowForm(false)}
                >
                  Cancel
                </button>
                <button className={"btn btn-primary btn-sm"}>Submit</button>
              </div>
            </div>
          </Form>
        </Formik>
      )}
      {!showForm && pre_sale_code && (
        <div>
          Got a pre-sale code?{" "}
          <button className={"link"} onClick={() => setShowForm(true)}>
            Click here to enter it.
          </button>
        </div>
      )}
    </>
  );
}
