import Link from "next/link";
import React from "react";
import { Product } from "@models/models";
import { isRootDomain } from "@utils/get-menu-item-resolved-url";

function getPageInfo(
  product: Product,
): { path: string; label: string } | undefined {
  const label = product.brand?.more_from_creator_label
    ? product.brand.more_from_creator_label
    : "See more from this creator";
  return product.brand?.is_show_more_from_creator_enabled
    ? {
        label,
        path: isRootDomain() ? `/b/${product.brand?.slug}` : "/index",
      }
    : undefined;
}

export default function MoreFromCategoryLink({
  product,
}: {
  product: Product;
}) {
  const page_info = getPageInfo(product);
  return (
    <div className={"my-4 text-center text-sm"}>
      {page_info && (
        <Link className={"link"} href={page_info.path}>
          {page_info.label}
        </Link>
      )}
    </div>
  );
}
