import { ContentBlockDisplayFormat, Participant } from "../models/models";
import Link from "next/link";
import { FaInstagram, FaLink, FaTiktok, FaTwitter } from "react-icons/fa";
import React from "react";
import Image from "next/image";

type ParticipantSocialProps = {
  participant: Participant;
  largeIcons?: boolean;
  hideCta?: boolean;
};
export function ParticipantSocial({
  participant,
  largeIcons,
  hideCta,
}: ParticipantSocialProps) {
  return (
    <>
      {(participant.instagramProfile ||
        participant.twitterProfile ||
        participant.tiktokProfile ||
        participant.websiteUrl ||
        participant.contentBlocks.length !== 0) && (
        <div className={"flex gap-x-3 mb-4 items-center"}>
          {!hideCta && (
            <div className={`font-bold ${largeIcons ? "text-2xl" : ""}`}>
              Follow
            </div>
          )}

          <div
            className={`flex items-center ${largeIcons ? "gap-x-4" : "gap-x-2"}`}
          >
            {!participant.contentBlocks.length && (
              <>
                {participant.instagramProfile && (
                  <div className={`${largeIcons ? "text-2xl" : ""}`}>
                    <Link
                      target="_blank"
                      href={`https://instagram.com/${participant.instagramProfile}`}
                    >
                      <FaInstagram />
                    </Link>
                  </div>
                )}
                {participant.twitterProfile && (
                  <div className={`${largeIcons ? "text-2xl" : ""}`}>
                    <Link
                      target="_blank"
                      href={`https://twitter.com/${participant.twitterProfile}`}
                    >
                      <FaTwitter />
                    </Link>
                  </div>
                )}
                {participant.tiktokProfile && (
                  <div className={`${largeIcons ? "text-2xl" : ""}`}>
                    <Link
                      target="_blank"
                      href={`https://tiktok.com/@${participant.tiktokProfile}`}
                    >
                      <FaTiktok />
                    </Link>
                  </div>
                )}
                {participant.websiteUrl && (
                  <div className={`${largeIcons ? "text-2xl" : ""}`}>
                    <Link target="_blank" href={`${participant.websiteUrl}`}>
                      <FaLink />
                    </Link>
                  </div>
                )}
              </>
            )}
            {participant.contentBlocks.length !== 0 &&
              participant.contentBlocks
                .filter(
                  (b) =>
                    b.contentBlock.displayFormat ===
                    ContentBlockDisplayFormat.HIGHLIGHT,
                )
                .map((b) => (
                  <React.Fragment key={b.id}>
                    {b.contentBlock?.thumbnail && (
                      <Link target="_blank" href={b.contentBlock.url}>
                        <Image
                          width={30}
                          height={30}
                          src={b.contentBlock.thumbnail}
                          alt={b.contentBlock.title}
                        />
                      </Link>
                    )}
                  </React.Fragment>
                ))}
          </div>
        </div>
      )}
    </>
  );
}
