import { useAppDispatch } from "@store/store";
import { addError, addSuccess, clearMessages } from "@store/messages-slice";
import { setIsLoading } from "@store/site-slice";
import _ from "lodash";
import toast from "react-hot-toast";
import React from "react";
import { scrollToTop } from "@utils/scroll-to-top";
import { extractErrorMessage } from "@jumpcomedy/utils";
type Func<T extends any[], R> = (...args: T) => Promise<R | undefined>;

type DisplayType = "toast" | "alert";

interface Options {
  successMessage?: string | React.ReactNode;
  successDisplay?: DisplayType;
  errorDisplay?: DisplayType;
  onErrorHandler?: (error: any) => void;
  showLoader?: boolean;
}

export function useErrorHandler<T extends any[], R>(
  func: Func<T, R>,
  options?: Options,
): (...args: T) => Promise<R | undefined> {
  const dispatch = useAppDispatch();

  return async function (...args: T) {
    let result;
    dispatch(clearMessages());
    if (options?.showLoader) {
      dispatch(setIsLoading(true));
    }
    try {
      result = await func(...args);
      if (options?.successMessage) {
        if (options?.successDisplay === "alert") {
          dispatch(addSuccess(options.successMessage));
          scrollToTop();
        } else {
          if (React.isValidElement(options.successMessage)) {
            toast.custom(options.successMessage);
          } else if (_.isString(options.successMessage)) {
            toast.success(options.successMessage);
          } else {
            console.log("Could not toast ", options.successMessage);
          }
        }
      }
    } catch (error) {
      if (options?.onErrorHandler) {
        options.onErrorHandler(error);
      }
      const errorMessage = extractErrorMessage(error);
      if (options?.errorDisplay === "alert") {
        dispatch(addError(errorMessage));
        scrollToTop();
      } else {
        toast.error(errorMessage);
      }
    }
    if (options?.showLoader) {
      dispatch(setIsLoading(false));
    }
    return result;
  };
}

export function useNonModalErrorHandler<T extends any[], R>(
  func: Func<T, R>,
  options?: Options,
): (...args: T) => Promise<R | undefined> {
  return useErrorHandler(func, {
    errorDisplay: "alert",
    successDisplay: "alert",
    ...options,
  });
}
