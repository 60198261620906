import { Message } from "./message";
import { Form, Formik } from "formik";
import { TextInput } from "./forms/text-input";
import { useState } from "react";
import { useErrorHandler } from "@hooks/error-handler";
import { useCreateProductAvailableNotificationMutation } from "@store/adsSlice";

export type NotifyWhenProductAvailableValues = {
  name: string;
  email: string;
  product_id: string;
};

export default function NotifyWhenProductAvailableForm({
  productId,
}: {
  productId: string;
}) {
  const [success, setSuccess] = useState(false);
  const [createProductAvailableNotification] =
    useCreateProductAvailableNotificationMutation();

  const onSubmit = useErrorHandler(
    async (values: NotifyWhenProductAvailableValues) => {
      await createProductAvailableNotification(values).unwrap();
      setSuccess(true);
    },
  );

  return (
    <Formik
      initialValues={{ product_id: productId, name: "", email: "" }}
      onSubmit={onSubmit}
    >
      {success ? (
        <div className={"mt-2"}>
          <Message
            message={"We'll notify you when tickets are available."}
            type={"success"}
            title={"Thank you"}
          />
        </div>
      ) : (
        <Form className={"flex flex-col gap-y-4"}>
          <div
            className={"flex flex-col gap-y-2 max-w-xs bg-gray-100 rounded p-4"}
          >
            <TextInput label={"Name"} type={"text"} name={"name"} />
            <TextInput label={"Email"} type={"email"} name={"email"} />
            <div className={"flex"}>
              <button className={"btn btn-primary"} type={"submit"}>
                Notify Me When Available
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
