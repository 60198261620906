import { useCopyToClipboard } from "usehooks-ts";
import toast from "react-hot-toast";

export default function CopyToClipboard({
  children,
  text,
  message,
  onCopy,
}: {
  children?: React.ReactNode;
  text: string;
  message?: string;
  onCopy?: () => void;
}) {
  const [copiedText, copy] = useCopyToClipboard();
  return (
    <div>
      <button
        type={"button"}
        onClick={() => {
          copy(text);
          toast.success(message ? message : "Copied text!");
          if (onCopy) {
            onCopy();
          }
        }}
      >
        {children}
      </button>
    </div>
  );
}
