import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";

type ProductQuantitySelectorProps = {
  setSelectedQuantity: (quantity: number) => void;
  max_add_to_cart_quantity?: number;
  default_add_to_cart_quantity?: number;
};
export default function ProductQuantitySelector({
  setSelectedQuantity,
  max_add_to_cart_quantity,
  default_add_to_cart_quantity,
}: ProductQuantitySelectorProps) {
  const [showManualQuantity, setShowManualQuantity] = useState(false);
  const quantityRef = useRef<HTMLInputElement>(null);
  const [quantity, setQuantity] = useState(
    default_add_to_cart_quantity ? default_add_to_cart_quantity : 2,
  );

  useEffect(() => {
    if (quantityRef.current) quantityRef.current.focus();
  }, [quantityRef, showManualQuantity]);

  const updateQuantity = (quantity: string) => {
    if (quantity.trim() === "") return;
    if (quantity === "10+") {
      setShowManualQuantity(true);
      return;
    }
    const numQuantity = parseInt(quantity);
    if (isNaN(numQuantity)) return;
    setSelectedQuantity(numQuantity);
  };

  return (
    <div className={"flex justify-center w-full"}>
      <div className={"flex flex-col w-full items-center"}>
        <label
          htmlFor="quantity"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Quantity
        </label>
        <div>
          {!showManualQuantity ? (
            <select
              id="quantity"
              name="location"
              onChange={(e) => updateQuantity(e.target.value)}
              className="select select-bordered w-64 max-w-xs"
              defaultValue={quantity}
            >
              {max_add_to_cart_quantity ? (
                <>
                  {_.range(1, max_add_to_cart_quantity + 1).map((quantity) => (
                    <option key={quantity}>{quantity}</option>
                  ))}
                </>
              ) : (
                <>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                  <option>10+</option>
                </>
              )}
            </select>
          ) : (
            <input
              ref={quantityRef}
              autoComplete={"off"}
              name={"quantity"}
              type={"text"}
              onChange={(e) => updateQuantity(e.target.value)}
              className="input input-bordered"
            />
          )}
        </div>
      </div>
    </div>
  );
}
